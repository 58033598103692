@font-face {
  font-family: Outfit;
  font-weight: 300;
  src: url('/fonts/Outfit-Light.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: Outfit;
  font-weight: 400;
  src: url('/fonts/Outfit-Regular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: Outfit;
  font-weight: 500;
  src: url('/fonts/Outfit-Medium.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: Outfit;
  font-weight: 600;
  src: url('/fonts/Outfit-SemiBold.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: Outfit;
  font-weight: bold;
  src: url('/fonts/Outfit-Bold.ttf') format('truetype');
  font-display: swap;
}
